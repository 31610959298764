var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';
import NavItem from './NavItem';
export default function NavList(_a) {
    var _b;
    var data = _a.data, depth = _a.depth, hasChild = _a.hasChild;
    var pathname = useLocation().pathname;
    var _c = useActiveLink(data.path), active = _c.active, isExternalLink = _c.isExternalLink;
    var _d = useState(active), open = _d[0], setOpen = _d[1];
    useEffect(function () {
        if (!active) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    var handleToggle = function () {
        setOpen(function (prev) { return !prev; });
    };
    var handleClose = function () {
        setOpen(false);
    };
    var currentPath = useLocation();
    var subChildOpen = (_b = data === null || data === void 0 ? void 0 : data.children) === null || _b === void 0 ? void 0 : _b.find(function (elt) { return elt.path === currentPath.pathname; });
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { item: data, depth: depth, open: open, active: subChildOpen ? !!subChildOpen : active, isExternalLink: isExternalLink, onClick: handleToggle }), hasChild && (_jsx(Collapse, __assign({ in: subChildOpen ? !!subChildOpen : open, unmountOnExit: true }, { children: _jsx(NavSubList, { data: data.children, depth: depth }) })))] }));
}
function NavSubList(_a) {
    var data = _a.data, depth = _a.depth;
    return (_jsx(_Fragment, { children: data.map(function (list) { return (_jsx(NavList, { data: list, depth: depth + 1, hasChild: !!list.children }, nanoid())); }) }));
}
